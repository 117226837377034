import { useQuery } from "react-query";
import {
    ACHIEVEMENTS,
    get,
    ORGANIZATIONS,
    RANKINGS,
    RANKS,
    SETTINGS,
    USERS
} from "./Client";

export function useGetPublicOrganizations() {
    const {
        status,
        data,
        error
    } = useQuery(['public', ORGANIZATIONS], () => get('public/' + ORGANIZATIONS));
    return { status, organizations: data || [], error };
}

export function useGetPublicOrganization(id, isAll) {
    const {
        status,
        data,
        error
    } = useQuery(['public', ORGANIZATIONS, { id: id }], () => get('public/' + ORGANIZATIONS, { elem: id }),
        { enabled: !isAll }
    );
    return { status, organization: data || {}, error };
}

export function useGetPublicOrganizationSettings(id, options = {}) {
    const {
        status,
        data,
        error
    } = useQuery(['public', ORGANIZATIONS, { id: id }, SETTINGS], () => get('public/' + ORGANIZATIONS, { elem: id + "/" + SETTINGS }), { enabled: !!id && id != 'all', ...options });
    return { status, settings: data || [], error };
}

export function useGetPublicOrganizationRanks(id, isAll) {
    const {
        status,
        data,
        error
    } = useQuery(['public', ORGANIZATIONS, { id: id }, RANKINGS], () => get('public/' + ORGANIZATIONS, { elem: id + "/" + RANKINGS }),
        { enabled: isAll }
    );
    return { status, ranks: data || [], error };
}

export function useGetPublicOrganizationRanksForAll(isAll) {
    const {
        status,
        data,
        error
    } = useQuery(['public', RANKINGS], () => get('public/' + RANKINGS),
        { enabled: !isAll }
    );
    return { status, ranks: data || [], error };
}

export function useGetPublicRank(id) {
    const {
        status,
        data,
        error
    } = useQuery(['public', RANKS, id], () => get('public/' + RANKINGS, { elem: id }));
    return { status, rank: data || {}, error };
}

export function useGetPublicUser(id) {
    const {
        status,
        data,
        error
    } = useQuery(['public', USERS, id], () => get('public/' + USERS, { elem: id }));
    return { status, user: data || {}, error };
}

export function useGetPublicUserAchievements(id) {
    const {
        status,
        data,
        error
    } = useQuery(['public', ACHIEVEMENTS, id], () => get('public/' + USERS, { elem: id + "/" + ACHIEVEMENTS }));
    return { status, achievements: data || [], error };
}

export function useGetPublicUserRankings(id) {
    const {
        status,
        data,
        error
    } = useQuery(['public', RANKS, id], () => get('public/' + USERS, { elem: id + "/" + RANKINGS }));
    return { status, ranks: data || [], error };
}
