import Grid from "@mui/material/Grid";
import NTMXGrid from "../../components/NTMXGrid";
import React, { useState } from "react";
import { Divider, IconButton, Toolbar, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RenderCell from "../../components/cellRender/RenderCell";
import useGetUsefulValues from "../../constants/rankingUsefulValues";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ORGANIZATIONS, RANKINGS } from "services/Client";
import OpenDataRankInfo from "./OpenDataRankInfo";
import { useGetPublicOrganizationRanks, useGetPublicOrganizationRanksForAll } from "services/ContentOpenManager";

export default function OpenDataRanks({ interval }) {

    const { t } = useTranslation();
    let { id } = useParams();
    let isAll = id == "all" ? true : false;
    let { ranks = [], status } = useGetPublicOrganizationRanks(id, !isAll);
    let { ranks: allRanks = [], status: allStatus } = useGetPublicOrganizationRanksForAll(!isAll);

    if (isAll) {
        ranks = allRanks;
        status = allStatus;
    }

    ranks = (!isAll ? ranks : allRanks).slice().sort((a, b) => a.startDate - b.startDate);
    const unexpiredRanks = ranks.slice().filter((e) => e.endDate >= Date.now());
    const expiredRanks = ranks.slice().filter((e) => e.endDate < Date.now());
    const usefulValues = useGetUsefulValues(false);

    let [showInfo, setShowInfo] = useState(null);
    let [showAwards, setShowAwards] = useState(null);
    ranks = ranks.slice().sort((a, b) => a.startDate - b.startDate);
    const key = [ORGANIZATIONS, { id: id }, RANKINGS];

    const defaultColumns = [
        {
            headerName: t('title'),
            field: 'title',
            width: 400,
            renderCell: (params) => <RenderCell params={params} />
        },
        {
            headerName: t('value'),
            field: 'value',
            width: 200,
            valueGetter: (params) => usefulValues.find(elem => elem.id === params.value) ? usefulValues.find(elem => elem.id === params.value)?.name : "",
            renderCell: (params) => <RenderCell params={params} />
        },
        {
            headerName: t('startDate'),
            field: 'startDate',
            width: 200,
            renderCell: (params) => <RenderCell params={params} type="date" />,
        },
        {
            headerName: t('endDate'),
            field: 'endDate',
            width: 200,
            renderCell: (params) => <RenderCell params={params} type="date" />,
        },
        {
            headerName: ' ',
            field: "info",
            width: 64,
            align: 'center',
            renderCell: (params) => <>
                <IconButton onClick={() => setShowInfo(params.row)} size="large"> <VisibilityIcon /> </IconButton>
            </>
        }
    ];

    if (showInfo) return <OpenDataRankInfo goBack={() => setShowInfo(null)} rankId={showInfo.id} />

    return <Grid>
        <Toolbar>
            <Grid container justifyContent="center" alignItems="center">
                <Typography variant="h6">
                    {t('unexpiredRanks')}
                </Typography>
            </Grid>
        </Toolbar>
        <NTMXGrid
            key={"ranks-manager-table-active"}
            columns={defaultColumns}
            rows={unexpiredRanks || []}
            title={t('unexpiredRanks')}
            loading={status === "loading"}
            disableToolbar={true}
        />
        <Divider sx={{ margin: "24px 0" }} />
        <Toolbar>
            <Grid container justifyContent="center" alignItems="center">
                <Typography variant="h6">
                    {t('expiredRanks')}
                </Typography>
            </Grid>
        </Toolbar>
        <NTMXGrid
            key={"ranks-manager-table-expired"}
            columns={defaultColumns}
            rows={expiredRanks || []}
            title={t('expiredRanks')}
            loading={status === "loading"}
            disableToolbar={true}
        />
    </Grid>

}
