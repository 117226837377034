import {Ability, AbilityBuilder} from "@casl/ability"
import CustomAwards from "../containers/CustomAwards";

export const roles = {
    PINBIKER: 0,
    MANAGER: 2,
    VENDOR: 1,
    ADMIN: 3
};

const abilities = {
    WRITE: "write",
    READ: "read"
}

const resources = {
    ALL_USERS: "allUsers",
    NATIONALACHIEVEMENTS: "nationalAchievements",
    NATIONALRANKS: "nationalRanks",
    DRINKINGFOUNTAINS: "drinkingFountains",
    DEBUG: "debug",
    REVOLUT: "revolut",
    SHOP: "shop",
    VENDOR: "vendor",
    VENDOR_PROFILE: "vendorProfile",
    COUPONS: "coupons",
    PRIVATEUSERDATA: "privateUserData",
    USER_WORK_ADDRESSES: "userWorkAddresses",
    RANKSAWARDS: "ranksAwards",
    NEW_VENDOR: "newVendor",
    OFFICES: 'offices',
    DELETE_ACHIEVEMENTS: 'deleteAchievements',
    DELETE_RANKS: 'deleteRanks',
    WELCOME_MESSAGES: 'welcomeMessages',
    ALL_ORG_CUSTOM_AWARD: "allOrgCustomAwards",
    SETTINGS: "settings",
    OPEN_DATA_SETTINGS: "openDataSettings",
};

function subjectName(item) {
    if (!item || typeof item === "string") {
        return item
    }
    return item.__type
}

function defineRulesFor(user) {

    // oid Brescia
    const BRESCIA_MOBILITA = 49;

    const {can, cannot, rules} = new AbilityBuilder()
    if (user.userType === roles.ADMIN) {
        can(abilities.WRITE, "all")
        can(abilities.READ, "all");
    }
    if (user.userType === roles.VENDOR) {
        can(abilities.WRITE, resources.VENDOR);
        can(abilities.READ, resources.VENDOR);

        can(abilities.WRITE, resources.VENDOR_PROFILE)
        can(abilities.READ, resources.VENDOR_PROFILE);
    }
    if (user.userType === roles.MANAGER) {
        can(abilities.WRITE, resources.OFFICES);
        can(abilities.READ, resources.OFFICES);

        // Possibilità di leggere indirizzi di lavoro solo per BRESCIA
        if (user.organization === BRESCIA_MOBILITA){
            can(abilities.READ, resources.USER_WORK_ADDRESSES);
        }
        
        can(abilities.WRITE, resources.SHOP);
        can(abilities.READ, resources.SHOP);
    }
    if (user.userType === roles.PINBIKER) {
        can(abilities.WRITE, resources.NEW_VENDOR);
        can(abilities.READ, resources.NEW_VENDOR);
    }
    return rules
}

function ability(user) {
    return new Ability(defineRulesFor(user || {}), {subjectName});
}

export default ability;

export {
    resources
}
